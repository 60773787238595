:root {
  --darkendGreen: #538d4e;
  --black: rgb(18, 18, 19);
  --gray-3: #3a3a3c;
  --gray-1: #909090;

  --locked: var(--darkendGreen);
  --background: var(--black);
  --background-image: linear-gradient(0deg, var(--black), rgba(0, 0, 0));
  --border: var(--gray-3);
  --text: var(--gray-1);
}

body {
  background-color: var(--background);
  color: var(--text);
  height: 100vh;
}

#solutions {
  display: flex;
  justify-content: space-around;
  position: absolute;
  animation: conceal 1s forwards;
  opacity: 0;
  color: #999;
  background-image: var(--background-image);
  border-bottom: #666 0.05px solid;
  z-index: 666;
  transition: all 1s;
  top: 0px;
  width: 100vw;
}

#solutions b {
  font-size: 0.5em;
  display: block;
  color: rgb(214, 179, 72);
}

.spoiler-alert {
  padding: 1rem;
  margin: 2rem;
  background-image: linear-gradient(328deg, #fa4107, #f0d826, #faa42a, #ff4900);
  border-radius: 1rem;
  border: rgb(124, 8, 8) solid 0.4rem;
}
.spoiler-alert h3 {
  color: black;
  border-radius: 2rem;
  font-size: 2rem;
  font-weight: 900;
}

button.heck-no {
  margin-bottom: 1rem;
  font-size: 1.7rem;
  border-radius: 0.7rem;
  border: none;
  padding: 0.7rem 1.5rem;
  background: #ce5dbc;
  font-weight: 900;
  color: black;
}

.alert-icon {
  color: #f60000;
  font-size: 4rem;
  background-color: white;
  border-radius: 100%;
}

.spoiler-alert p {
  color: black;
  font-weight: 300;
  font-size: 1.3rem;
}
.spoiler-alert button {
  border-radius: 0.5rem;
  padding: 5px 10px;
  border-width: 0;
  font-size: 1.1rem;
  background-color: rgb(124, 8, 8);
  /* background-image: linear-gradient(336deg, rgb(202, 17, 17), rgba(146, 1, 1, 0.881)); */
  color: rgb(231, 228, 228);
}

.cristalball {
  margin-top: 4rem;
  cursor: not-allowed;
  text-align: center;
  font-size: 2.5rem;
  display: inline-block;
  padding: 0 2rem;
  max-width: 355px;
  color: #3a3a3c;
  line-height: 2.8rem;
}

.cristalball > span {
  font-size: 5rem;
  line-height: 7rem;
  display: block;
}
.reveal {
  animation: reveal 1s forwards !important;
}
@keyframes reveal {
  from {
    transform: translateY(-800px);
  }
  to {
    transform: translateY(0);
  }
}
.conceal {
  animation: conceal 1s forwards;
}

@keyframes conceal {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-800px);
  }
}

.letters {
  display: flex;
  justify-content: center;
  align-content: center;
}
input[type='text'] {
  color: inherit;
  width: 10vw;
  height: 10vw;
  min-width: 30px;
  min-height: 30px;
  max-width: 100px;
  max-height: 100px;
  font-size: 6vw;
  text-align: center;
  caret-color: transparent;
  background-color: inherit;
  border: max(min(2px, 0.25vw), 0.75px) solid var(--border);
  margin: 0.25vw;
}
input[type='text']:focus {
  outline: 0.5px solid #666;
}

.pop {
  animation: pop 100ms;
  background-color: var(--gray-3) !important;
}
.locked {
  background-color: var(--locked) !important;
  color: white !important;
}
.char-lock{
  color: var(--locked);
}

header {
  text-align: center;
}

header h2{
  color: #a863ab;
}

header + div {
  text-align: center;
}

@keyframes pop {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  40% {
    transform: scale(1.1);
    opacity: 1;
  }
}

.word-result {
  font-size: 1.5rem;
}

footer {
  position: fixed;
  bottom: 0px;
  padding: 0.5rem 1rem;
  padding-top: 0;
  /* background-color: var(--background); */
  background-image: linear-gradient(
    336deg,
    hsl(271deg 25% 24%),
    rgba(0, 0, 0, 0.9)
  );
  margin: 0 auto;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  transition: transform 0.75s ease-in-out;
}
.keyboard-container {
  /* margin:0; */
  display: flex;
  align-content: center;
  flex-direction: column;
  width: 80%;
  max-width: 540px;
  position: relative;
}

.keyboard-container > h3 {
  cursor: pointer;
  color: rgb(160, 100, 160);
}

.keyboard-container > h3.active {
  color: rgb(165, 95, 141);
}
.keyboard-container > h3 > span {
  font-size: 0.7em;
  opacity: 0.7;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.keyboard-container > h3.active > span {
  transition: opacity 0.4s ease-in-out;
  display: inline;
  opacity: 1;
}

.keyboard {
  display: grid;
  grid-template: 1fr 1fr 1fr / repeat(9, 1fr);
}
.keyboard-icon {
  position: absolute;
  top: -40px;
  right: 0;
  background-image: linear-gradient(
    180deg,
    hsl(271deg 30% 44%),
    rgba(20, 22, 36, 0.9) 20%,
    rgba(20, 22, 36, 0)
  );
  padding: 0.7rem 1rem 2rem;
  border-radius: 10px;
  color: #ccc;
}

.key {
  padding: min(1rem, 2vw) min(1rem, 4vw);
  background-color: var(--gray-3);
  margin: min(5px, 1vw);
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
.key:focus {
  outline: none;
}
.key.clear {
  background-color: #222;
  color: red;
}
.caret {
  display: inline-block;
  transition: transform 0.7s ease-in-out;
  margin-right: 10px;
  transform: rotate(90deg);
  color: rgb(200, 180, 80);
}

.rotate {
  transform: rotate(0deg);
}

.excluded {
  background-color: rgb(66, 0, 0) !important;
}

span.credit {
  font-size: 0.6rem;
  margin-top: 10px;
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
  color: #666;
  cursor: zoom-in;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@media screen and (min-width: 100px) and (max-width: 450px) {
  .keyboard {
    grid-template: 1fr 1fr 1fr 1fr 1fr / repeat(4, 1fr);
  }
}

@media screen and (min-width: 451px) and (max-width: 600px) {
  .keyboard {
    grid-template: 1fr 1fr 1fr 1fr / repeat(7, 1fr);
  }
}
