:root{
  --color-tone-1: #ffffff;
    --color-tone-2: #818384;
    --color-tone-3: #565758;
    --color-tone-4: #3a3a3c;
    --color-tone-5: #272729;
    --color-tone-6: #1a1a1b;
    --color-tone-7: #121213;
    --color-nav-hover: #2f2f31;
    --opacity-50: rgba(0, 0, 0, 0.5);
    --ff-sans: -apple-system,BlinkMacSystemFont,"Segoe UI","Liberation Sans",sans-serif;
    --ff-serif: Georgia,Cambria,"Times New Roman",Times,serif;
    --ff-mono: ui-monospace,"Cascadia Mono","Segoe UI Mono","Liberation Mono",Menlo,Monaco,Consolas,monospace;
    --s-prose-line-height: 1.5;
    --s-prose-spacing: 1.1em;
    --s-prose-spacing-condensed: calc(var(--s-prose-spacing) / 2);
    --white: hsl(0,0%,100%);
    --black: hsl(210,8%,5%);
    --orange: hsl(27,90%,55%);
    --yellow: hsl(47,83%,91%);
    --green: hsl(140,40%,55%);
    --blue: hsl(206,100%,40%);
    --powder: hsl(205,46%,92%);
    --red: hsl(358,62%,52%);
    --black-025: hsl(210,8%,97.5%);
    --black-050: hsl(210,8%,95%);
    --black-075: hsl(210,8%,90%);
    --black-100: hsl(210,8%,85%);
    --black-150: hsl(210,8%,80%);
    --black-200: hsl(210,8%,75%);
    --black-300: hsl(210,8%,65%);
    --black-350: hsl(210,8%,60%);
    --black-400: hsl(210,8%,55%);
    --black-500: hsl(210,8%,45%);
    --black-600: hsl(210,8%,35%);
    --black-700: hsl(210,8%,25%);
    --black-750: hsl(210,8%,20%);
    --black-800: hsl(210,8%,15%);
    --black-900: hsl(210,8%,5%);
  }


.instructions h2{
  color: var(--color-tone-2);
  margin-bottom: 2rem;
}

.instruction-title{
  color: #824e82;
}

.instruction-title span{
  color: var(--gray-1);
  font-weight: 400;
}


.question-icon{
  width: 5px;
  height: 5px;
  padding: 10px;
  color: var(--color-tone-2);
  border: var(--color-tone-3) 2px solid;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  line-height: 0;
  font-size: 1.1rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.close-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.question-icon, .close-icon{
  cursor: pointer;
}

.overlay {
  display: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: var(--opacity-50);
  z-index: 1;
  overflow: hidden !important;
}

.content {
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--color-tone-6);
  background-color: rgba(18, 18, 18, .9);
  color: var(--color-tone-1);
  box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.2);
  width: 90vw;
  max-height: 90vh;
  max-width: 680px;
  overflow-y: auto;
  animation: SlideIn 200ms;
  padding: 16px;
  box-sizing: border-box;
}

.content.closing {
  animation: SlideOut 200ms;
}

.example{
  margin-bottom: 2rem;
}

.example p:first-of-type{
  margin-bottom: 0.6rem;
}
.example p:first-of-type>strong{
  font-size: 1.7rem;
  color: #a8a8a8;
}
.example p:last-child{
  margin-top: 0.2rem;
}

.mouse{
  display: inline-flex;
  position: relative;
  top: 5px;
  font-size: 2rem;
  transform: rotate(-90deg);
  margin-top: -20px;
  line-height: 0;
}

.tile, .instructions .key{
  --size: 2vw;
  color:inherit;
  width: calc(var(--size) * 2);
  height:calc(var(--size) * 2);
  max-width: 40px;
  max-height: 40px;
  font-size:  min(calc(var(--size) * 1.4), 28px);
  text-align: center;
  caret-color: transparent;
  background-color: inherit;
  border: max(min(2px, .25vw), .75px) solid var(--border);
  margin: .25vw;
  display: inline-block;
}

.instructions .key{
  padding: .1rem;
  border-radius: 5px;
  max-width: 20px;
  max-height: 20px;
  font-size:  14px;
}

.result-words{
  font-size: .8rem;
  color: var(--color-tone-2)
}

@keyframes SlideIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes SlideOut {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(60px);
  }
}


/* KEYBOARD BUTTON REPRS */
kbd {
  display: inline-block;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  font-family: var(--ff-sans);
  font-size: 11px;
  line-height: var(--s-prose-line-height);
  color: var(--black-800);
  text-shadow: 0 1px 0 var(--white);
  background-color: var(--black-075);
  border: 1px solid var(--black-300);
  border-radius: 3px;
  box-shadow: 0 1px 1px hsl(210deg 8% 5% / 15%), inset 0 1px 0 0 hsl(0deg 0% 100%);
  overflow-wrap: break-word;
}

hr{
  border: #3a3a3c 0.5px solid;
}